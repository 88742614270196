import { Navigate, useParams } from 'react-router-dom';

type ParameterNavigateProps = {
    to: string;
};

export const ParameterNavigate: React.FC<ParameterNavigateProps> = (props) => {
    const params = useParams();

    const to = Object.entries(params).reduce((url, [key, value]) => {
        if (value) {
            return url.replace(`:${key}`, value);
        }

        return url;
    }, props.to);

    return <Navigate to={to} replace />;
};
