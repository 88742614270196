import {
    Body1Strong,
    Card,
    CardHeader,
    CardProps,
    makeStyles,
    mergeClasses,
    shorthands,
    Skeleton,
    SkeletonItem,
    Subtitle1,
    tokens,
} from '@fluentui/react-components';
import { DependencyList, Fragment, isValidElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useAccount } from '../hooks/use-account';
import { Link } from './Link';
import { TriggerSource } from './models';
import { isUuid } from './uuid';

export type RowRenderer<T> = {
    (item: T): React.ReactElement | any | undefined;
};

export type RowConfig<T> = {
    indent?: 'single' | 'double';
    labelClass?: string;
    optional?: boolean;
    onRender: RowRenderer<T>;
};

export type Row<T> = RowConfig<T> & {
    id: string;
    label: string;
};

export type DetailsViewProps<T = any> = {
    rows: Row<T>[];
    item?: T;
    title?: string | React.ReactElement;
    appearance?: CardProps['appearance'] | 'outline-alternative';
    className?: string;
};

export type TriggerFieldFactory<T> = {
    (label: string, selector: (entity: T) => TriggerSource | undefined): void;
};

export type FieldFactory<T> = {
    (label: string, renderOrConfig?: RowRenderer<T> | RowConfig<T>): void;
};

function getRow<T>(label: string, renderOrConfig?: RowRenderer<T> | RowConfig<T>): Row<T> {
    const id = uuid();

    if (!renderOrConfig) {
        return { id, label, onRender: () => <></> };
    } else if (typeof renderOrConfig === 'function') {
        return { id, label, onRender: renderOrConfig };
    } else {
        return { id, label, ...renderOrConfig };
    }
}

export const useDetailsView = <T,>(
    configure: (helpers: { field: FieldFactory<T>; triggerField: TriggerFieldFactory<T> }) => void,
    deps: DependencyList = [],
): Row<T>[] => {
    const { permissions } = useAccount();

    const { t } = useTranslation('common');

    return useMemo(() => {
        const rows: Row<T>[] = [];

        const field: FieldFactory<T> = (label, renderOrConfig) => {
            rows.push(getRow<T>(label, renderOrConfig));
        };

        const triggerField: TriggerFieldFactory<T> = (label, selector) => {
            const onRenderLink = (link: any, id?: string, url?: string) => {
                if (id) {
                    if (link) {
                        return <Link href={url}>{id}</Link>;
                    }

                    return id;
                }
            };

            const by = (render: RowRenderer<TriggerSource>) => {
                return (entity: T) => {
                    const by = selector(entity);

                    if (by) {
                        return render(by);
                    }
                };
            };

            rows.push(
                getRow<T>(label, { optional: true, onRender: by((by) => by.type) }),
                getRow<T>(t('distributorId'), {
                    optional: true,
                    indent: 'single',
                    onRender: by(({ distributorId }) =>
                        onRenderLink(permissions.distributors.view, distributorId, `/distributors/${distributorId}`),
                    ),
                }),
                getRow<T>(t('shopId'), {
                    optional: true,
                    indent: 'single',
                    onRender: by(({ shopId }) =>
                        onRenderLink(permissions.shops.view && shopId && isUuid(shopId), shopId, `/shops/${shopId}`),
                    ),
                }),
                getRow<T>(t('operatorId'), {
                    optional: true,
                    indent: 'single',
                    onRender: by(({ operatorId }) =>
                        onRenderLink(permissions.users.view && operatorId && isUuid(operatorId), operatorId, `/users/${operatorId}`),
                    ),
                }),
                getRow<T>(t('merchantId'), {
                    optional: true,
                    indent: 'single',
                    onRender: by(({ merchantId }) => onRenderLink(permissions.merchants.view, merchantId, `/merchants/${merchantId}`)),
                }),
                getRow<T>(t('customerId'), {
                    optional: true,
                    indent: 'single',
                    onRender: by(({ customerId }) => onRenderLink(permissions.customers.view, customerId, `/customers/${customerId}`)),
                }),
                getRow<T>(t('posDevice'), { optional: true, indent: 'single', onRender: by(({ posDevice }) => posDevice?.type) }),
                getRow<T>(t('id'), {
                    optional: true,
                    indent: 'double',
                    onRender: by(({ posDevice }) =>
                        onRenderLink(
                            permissions.distributors.view && posDevice?.id && isUuid(posDevice.id),
                            posDevice?.id,
                            `/devices/${posDevice?.id}`,
                        ),
                    ),
                }),
                getRow<T>(t('appInstallationId'), {
                    optional: true,
                    indent: 'double',
                    onRender: by(({ posDevice }) => posDevice?.appInstallationId),
                }),
            );
        };

        configure({ field, triggerField });

        return rows;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions, t, ...deps]);
};

export const DetailsView: React.FC<DetailsViewProps> = (props) => {
    const classes = useStyles();

    const renderRow = (row: RowConfig<any>) => {
        if (props.item) {
            const render = row.onRender(props.item);

            if (render !== null && render !== undefined) {
                if (isValidElement(render)) {
                    return render;
                } else {
                    return render.toString();
                }
            }
        }
    };

    return (
        <Card
            className={mergeClasses(props.className, props.appearance === 'outline-alternative' && classes.alternative)}
            appearance={props.appearance === 'outline-alternative' ? 'outline' : props.appearance}
            size="large"
        >
            {props.title && <CardHeader header={<Subtitle1>{props.title}</Subtitle1>} />}
            {props.item ? (
                <div className={classes.root}>
                    {props.rows
                        .map((row) => ({ ...row, render: renderRow(row) }))
                        .filter((row) => row.render || !row.optional)
                        .map((row) => (
                            <Fragment key={row.id}>
                                <Body1Strong
                                    className={mergeClasses(
                                        classes.label,
                                        row.indent === 'single' && classes.singleIndent,
                                        row.indent === 'double' && classes.doubleIndent,
                                        row.labelClass,
                                    )}
                                >
                                    {row.label}
                                </Body1Strong>
                                <div className={classes.detail}>{row.render}</div>
                            </Fragment>
                        ))}
                </div>
            ) : (
                <Skeleton className={mergeClasses(classes.root, classes.skeleton)}>
                    {props.rows
                        .filter((row) => !row.optional)
                        .map((row) => (
                            <Fragment key={row.id}>
                                <SkeletonItem size={16} />
                                <SkeletonItem size={16} />
                            </Fragment>
                        ))}
                </Skeleton>
            )}
        </Card>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateColumns: '175px 1fr',
        gap: '8px',
        '@media print': {
            fontSize: '8pt',
            gap: '8pt',
        },
    },
    alternative: {
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.borderColor(tokens.colorNeutralStroke1),
    },
    skeleton: {
        gap: '12px 8px',
        padding: '2px 0',
    },
    label: {
        '@media print': {
            fontSize: '8pt',
        },
    },
    detail: {
        wordBreak: 'break-word',
    },
    singleIndent: {
        paddingLeft: '14px',
        '@media print': {
            paddingLeft: '6pt',
        },
    },
    doubleIndent: {
        paddingLeft: '20px',
        '@media print': {
            paddingLeft: '12pt',
        },
    },
});
