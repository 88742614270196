import {
    Button,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    makeStyles,
    MessageBar,
    MessageBarBody,
    OverlayDrawer,
    tokens,
} from '@fluentui/react-components';
import { Dismiss24Regular, History24Regular } from '@fluentui/react-icons';
import { format } from 'date-fns';
import { useState } from 'react';

import { usePageMessages } from '../hooks';
import { HeaderButton } from './HeaderButton';

export const MessageHistoryMenu: React.FC = () => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const { messages } = usePageMessages();

    return (
        <>
            <HeaderButton onClick={() => setOpen(true)}>
                <History24Regular />
            </HeaderButton>
            <OverlayDrawer position="end" size="medium" open={open} onOpenChange={(_, { open }) => setOpen(open)}>
                <DrawerHeader>
                    <DrawerHeaderTitle action={<Button appearance="subtle" icon={<Dismiss24Regular />} onClick={() => setOpen(false)} />}>
                        Message history
                    </DrawerHeaderTitle>
                </DrawerHeader>

                <DrawerBody className={classes.content}>
                    {messages
                        .sort((first, second) => second.time.getTime() - first.time.getTime())
                        .map((message) => (
                            <MessageBar
                                key={message.id}
                                intent={message.type}
                                className={classes.message}
                                icon={{ className: classes.icon }}
                            >
                                <MessageBarBody className={classes.body}>
                                    <span>{[message.message, message.details].filter((segment) => segment).join(' - ')}</span>
                                    <i className={classes.date}>{format(message.time, 'dd.MM.yyyy HH:mm')}</i>
                                </MessageBarBody>
                            </MessageBar>
                        ))}
                </DrawerBody>
            </OverlayDrawer>
        </>
    );
};

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalM,
    },
    message: {
        alignItems: 'start',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
        whiteSpace: 'normal',
        gap: tokens.spacingVerticalS,
    },
    date: {
        display: 'block',
        opacity: 0.75,
    },
    icon: {
        marginTop: tokens.spacingVerticalS,
    },
});
