import { Combobox, ComboboxProps, Field, makeStyles, mergeClasses, Option, OptionOnSelectData } from '@fluentui/react-components';
import { useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';

import { validate } from './helpers';
import { ControllerFieldProps } from './models';

export type ComboboxOption = {
    key: any;
    text: string;
};

export type ComboboxFieldProps = Omit<ComboboxProps, 'multiple' | 'children' | 'onSelect'> & {
    label?: string;
    options: ComboboxOption[];
    combobox?: { className?: string };
    listbox?: { className?: string };
    onSelect?: (option: ComboboxOption) => void;
};

export const ComboboxField: React.FC<ComboboxFieldProps & ControllerFieldProps> = ({
    control,
    rules,
    label,
    className,
    options,
    combobox,
    listbox,
    ...props
}) => {
    const classes = useStyles();

    const [search, setSearch] = useState<string>();
    const [selected, setSelected] = useState<ComboboxOption>();

    const getSelected = (key: any) => {
        if (selected) {
            return selected.text;
        }

        const option = options.find((option) => option.key === key);

        if (option) {
            // rerendering will show properly the selected option
            setSelected(option);
        }

        return key ?? '';
    };

    const filteredOptions = useMemo(() => {
        if (search) {
            const normalized = search.toLowerCase();

            return options.filter((option) => `${option.text} ${option.key}`.toLowerCase().includes(normalized));
        } else {
            return options;
        }
    }, [search, options]);

    const handleOptionSelect = (data: OptionOnSelectData, field: ControllerRenderProps<FieldValues, string>) => {
        if (data.optionValue) {
            const option = options.find((option) => option.key === data.optionValue);

            if (option) {
                setSearch(undefined);
                setSelected(option);

                props.onSelect?.(option);
                field.onChange(option.key);
            }
        }
    };
    const handleOpen = (open: boolean) => {
        if (open) {
            setSearch('');
        }
    };

    return (
        <Controller
            name={props.name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Field label={label} {...validate(fieldState)} className={className}>
                    <Combobox
                        disabled={props.disabled}
                        className={mergeClasses(classes.dropdown, combobox?.className)}
                        listbox={{ className: mergeClasses(classes.list, listbox?.className) }}
                        onOptionSelect={(_, data) => handleOptionSelect(data, field)}
                        selectedOptions={[field.value]}
                        value={search ?? selected?.text ?? getSelected(field.value)}
                        onBlur={() => setSearch(undefined)}
                        onChange={(e) => setSearch(e.target.value)}
                        onOpenChange={(_, data) => handleOpen(data.open)}
                    >
                        {filteredOptions.length === 0 && <Option disabled>No results</Option>}
                        {filteredOptions.map((option) => (
                            <Option key={option.key} value={option.key} text={option.text}>
                                {option.text}
                            </Option>
                        ))}
                    </Combobox>
                </Field>
            )}
        />
    );
};

const useStyles = makeStyles({
    dropdown: {
        minWidth: 'unset',
    },
    list: {
        maxHeight: '200px',
    },
    select: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});
