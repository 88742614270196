import { Navigate, useLocation } from 'react-router-dom';

export type PrefixRemoveNavigateProps = {
    prefix: string;
};

export const PrefixRemoveNavigate: React.FC<PrefixRemoveNavigateProps> = ({ prefix }) => {
    const { pathname, search } = useLocation();

    let path = pathname;

    if (path.startsWith(prefix)) {
        if (path.length > prefix.length) {
            path = pathname.substring(prefix.length);
        } else {
            path = '/';
        }
    }

    return <Navigate to={path + search} replace />;
};
