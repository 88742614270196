import { makeStyles, mergeClasses, Title3, tokens } from '@fluentui/react-components';

import { usePageTitle } from '../hooks';
import { FlyoutMenu } from './FlyoutMenu';
import { LanguageMenu } from './LanguageMenu';
import { MessageHistoryMenu } from './MessageHistoryMenu';
import { UserMenu } from './UserMenu';

export type HeaderProps = {
    className?: string;
    responsive: boolean;
};

export const Header: React.FC<HeaderProps> = (props) => {
    const classes = useStyles();
    const [title] = usePageTitle();

    return (
        <section className={mergeClasses(classes.root, props.className)}>
            <Title3 className={classes.title}>{title}</Title3>
            {props.responsive ? (
                <FlyoutMenu />
            ) : (
                <menu className={classes.menu}>
                    <LanguageMenu />
                    <MessageHistoryMenu />
                    <UserMenu />
                </menu>
            )}
        </section>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        boxShadow: tokens.shadow16,
        backgroundColor: tokens.colorBrandBackgroundHover,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '48px',
        padding: `${tokens.spacingVerticalSNudge} ${tokens.spacingHorizontalSNudge} ${tokens.spacingVerticalSNudge} ${tokens.spacingHorizontalL}`,
        gap: tokens.spacingVerticalSNudge,
        '@media print': {
            boxShadow: 'unset',
            paddingLeft: 0,
        },
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalXS,
    },
    title: {
        color: tokens.colorNeutralForegroundInverted,
        opacity: 0.7,
        lineHeight: 'unset',
        '@media print': {
            color: tokens.colorNeutralForeground1,
        },
    },
});
