import { makeStyles } from '@fluentui/react-components';
import { Children } from 'react';

import { Stack } from './Stack';

export type ActionBarProps = {
    floatLast?: boolean;
};

export const ActionBar: React.FC<React.PropsWithChildren<ActionBarProps>> = (props) => {
    const classes = useStyles();
    const actions = Children.toArray(props.children);
    const floated = props.floatLast ? actions.pop() : undefined;

    if (!actions?.length && !floated) {
        return null;
    }

    return (
        <Stack horizontal wrap className={classes.root} spacing="small">
            {floated ? (
                <>
                    <Stack horizontal wrap className={classes.action} spacing="small">
                        {actions}
                    </Stack>
                    <div>{floated}</div>
                </>
            ) : (
                actions
            )}
        </Stack>
    );
};

const useStyles = makeStyles({
    root: {
        ':empty': {
            display: 'none',
        },
    },
    action: {
        flexGrow: 1,
    },
});
