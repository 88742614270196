import { makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, tokens } from '@fluentui/react-components';
import { Globe24Regular } from '@fluentui/react-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultLanguage, supportedLanguages } from '../config';
import { HeaderButton } from './HeaderButton';

export const LanguageMenu: React.FC = () => {
    const classes = useStyles();

    const { i18n } = useTranslation();

    const [language, setLanguage] = useState(supportedLanguages.find((current) => current.code === i18n.language) ?? defaultLanguage);

    const changeLanguage = (code: string) => {
        const language = supportedLanguages.find((current) => current.code === code);

        if (language) {
            setLanguage(language);
            i18n.changeLanguage(language.code);
        }
    };
    return (
        <Menu positioning="below-end">
            <MenuTrigger disableButtonEnhancement>
                <HeaderButton>
                    <span className={classes.language}>{language.code.toUpperCase()}</span>
                    <Globe24Regular />
                </HeaderButton>
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {supportedLanguages.map((language) => (
                        <MenuItem key={language.code} onClick={() => changeLanguage(language.code)}>
                            {language.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

const useStyles = makeStyles({
    language: {
        marginLeft: tokens.spacingHorizontalXS,
        marginRight: tokens.spacingHorizontalXS,
    },
});
