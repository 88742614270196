import { Button, ButtonProps, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { forwardRef } from 'react';

export const HeaderButton = forwardRef<HTMLButtonElement, ButtonProps>(({ children, className, ...props }, ref) => {
    const classes = useStyles();

    return (
        <Button {...props} ref={ref} appearance="subtle" shape="circular" className={mergeClasses(classes.root)}>
            {children}
        </Button>
    );
});

const useStyles = makeStyles({
    root: {
        margin: '0',
        padding: tokens.spacingHorizontalXS,
        transition: `background-color ${tokens.durationFast} ${tokens.curveEasyEase}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: '48px',
        minWidth: '32px',
        color: 'rgba(255, 255, 255, 0.5)',
        ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: 'rgba(255, 255, 255, 0.65)',
        },
        ':active': {
            backgroundColor: 'rgba(255, 255, 255, 0.35)',
            color: 'rgba(255, 255, 255, 0.8)',
        },
        ':hover:active': {
            backgroundColor: 'rgba(255, 255, 255, 0.35)',
            color: 'rgba(255, 255, 255, 0.8)',
        },
    },
});
